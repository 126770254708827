.navbar {
  width: 100%;
  height: 10vh;
  background-image: url('../../assets/wood-background.png'); /* Replace with your wood background image */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-top: 1vh solid #FFEA00;
  font-family: 'Alegreya', serif; /* Apply Alegreya font */
  font-size: 2vh; /* Font size */
  font-weight: bold; /* Bold font */
  transition: top 0.3s; /* Smooth transition for hiding/showing */
}

.navbar.hidden {
  top: -10vh; /* Hide the navbar completely */
}

.navbar.visible {
  top: 0; /* Show the navbar */
}

.navbar-social {
  display: flex;
  gap: 1.5vw;
}

.navbar-social a {
  text-decoration: none;
  color: black;
}

.navbar-tabs-left {
  display: flex;
  gap: 2vw;
  flex: 1; /* Take up remaining space */
  justify-content: center; /* Center align tabs */
}

.navbar-tabs-left a {
  text-decoration: none;
  color: black;
  font-size: 1.5vw;
}

.navbar-logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-20%);
  z-index: 1001;
}

.navbar-logo img {
  width: 25%; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
}

.navbar-tabs-right {
  display: flex;
  gap: 2vw;
  flex: 1; /* Take up remaining space */
  justify-content: center; /* Center align tabs */
}

.navbar-tabs-right a {
  text-decoration: none;
  color: #A4867C;
  font-size: 1.5vw;
  background-color: black;
  color: transparent;
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
}

.navbar-cart {
  margin-right: 2vw;
}

.navbar-cart a {
  text-decoration: none;
  color: black;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.navbar,
.navbar-name,
.navbar-tabs a,
.navbar-social a,
.navbar-quote .quote-button {
  animation: fadeIn 1s ease-in-out;
}
