.project-inquiry-container {
  width: 75%;
  height: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
}



.project-inquiry {
  color: black;
  h2 {
    font-size: 24px; /* Adjust font size in pixels */
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: rgb(40, 40, 40);
  font-size: 20px;
  cursor: pointer;
}

.project-inquiry h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.form-group input {
  width: calc(50% - 5px); /* Adjust width to occupy 50% of the container with some margin */
  margin-right: 10px; /* Add margin between inputs */
}


.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: rgba(202, 202, 202);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.form-group textarea {
  resize: vertical;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgb(255, 200, 0);
  color: white;
  font-size: 16px; /* Adjust font size in pixels */
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: rgb(166, 137, 33);
}
