.location {
  width: 100%;
  background-color: lightgrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: -999;
  
}

.location iframe {
  margin: 50px;
  width: 300px; /* Adjust width as needed */
  height: 500px; /* Adjust height as needed */
  background-color: white; /* Placeholder color */
}

.info {
  flex-grow: 1; /* Allows the info section to expand and fill available space */
  margin-left: 20px; /* Add some spacing between image and info */
  margin-bottom: 50px;

  h2 {
    font-size: 50px;
  }

  .info-subsect{
    margin: auto;
    padding: 10px;
    p{
      font-size:20px;
    }
  }
  
  p {
    margin-top: 10px;
    color: #666; /* Adjust text color as needed */
  }

  .get-in-touch {
    background-color: black;
    width: 300px;
    height: 100px;
    font-size: 25px;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .get-in-touch:hover {
    background-color: black;
  }
  
}



