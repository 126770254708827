.reviews-container {
  text-align: center;
  padding: 20px;
  background: linear-gradient(to bottom, #262524, #000000);
  color: #ECE2D3;
}

.border-wrapper {
  position: relative;
  display: inline-block; /* Adjust as necessary */
}

.border-wrapper .rating-container {
  position: relative;
  z-index: 1;
  padding: 10px;
}

.border-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 110%;
  background: url('../../../assets/reviews/border.png') no-repeat center center;
  background-size: cover;
  z-index: 0;
}

.reviews-container h2,
.reviews-container h3 {
  margin: 0;
  font-family: 'Nova Mono';
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  font-family: 'Nova Mono';
}

.stars {
  display: flex;
  align-items: center;
}

.star {
  width: 24px; /* Adjust size as needed */
  height: 24px;
  object-fit: contain;
}

.star-container {
  position: relative;
  width: 24px; /* Same width as stars */
  height: 24px; /* Same height as stars */
}

.star-container .star {
  position: absolute;
  top: 0;
  left: 0;
}

.star.half {
  clip-path: inset(0 50% 0 0); /* Clips half of the star to represent a half star */
}

.rating-details {
  display: flex;
  align-items: center; /* Aligns items vertically center */
  margin-top: 10px;
}

.rating-score {
  font-size: 36px;
  font-weight: bold;
  margin-right: 10px; /* Space between the rating score and the text */
}

.rating-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns text to the left */
}

.rating-text span {
  display: block;
}

.review-bubbles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distributes space between items */
  margin-top: 5rem; /* Space above the bubbles container */
  margin-bottom: 7rem; /* Space below the bubbles container */
  width: 100%; /* Ensure container takes full width */
  max-width: 1200px; /* Limit maximum width if needed */
  margin-left: auto; /* Center container horizontally */
  margin-right: auto; /* Center container horizontally */
}

.review-bubble {
  width: calc(33.333% - 10px); /* Adjust for spacing between items */
  margin-bottom: 10px; /* Space between rows */
}

/* Optional: add responsiveness for smaller screens */
@media (max-width: 768px) {
  .review-bubble {
    width: calc(50% - 10px); /* Adjust width for smaller screens */
  }
}

@media (max-width: 480px) {
  .review-bubble {
    width: 100%; /* Full width on very small screens */
  }
}

.write-review-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #F0BE79; /* Adjust the color as needed */
  color: #262524;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.write-review-button:hover {
  background-color: rgb(163, 108, 52); /* Darker shade for hover effect */
}
