.contact {
    background-color: #E4DBD0;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    margin: 0 auto; /* Center the contact section horizontally */
}

.top {
    font-family: 'Urbanist';
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-info {
    font-family: 'Stylish';
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 300px; /* Adjust based on your layout needs */
}

.contact-info .item {
    font-family: 'Urbanist';
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.contact-info .item img {
    width: 30px;
    height: 24px;
}

.top img {
    max-width: 200px;
    height: auto;
}

.bottom-contact {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
}

.contact-form {
    font-family: 'Urbanist';
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 600px; /* Adjust based on your layout needs */
    margin: 0 auto; /* Center the form horizontally */
}

.contact-form h1 {
    font-family: 'Stylish';
    margin-bottom: 10px;
}

.contact-form .form-group {
    font-family: 'Urbanist';
    margin-bottom: 15px;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1.2px solid #000;
    background-color: transparent;
    color: #000;
    border-radius: 0;
    box-sizing: border-box;
}

.contact-form .form-group input::placeholder,
.contact-form .form-group textarea::placeholder {
    color: #999;
}

.contact-form .form-group textarea {
    height: 100px;
    resize: vertical;
}

.contact-form button {
    font-family: 'Urbanist';
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #E2BB7F;
    color: black;
    font-size: 16px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #eba947;
}

.contact-text {
    font-family: 'Urbanist';
    text-align: center;
    padding: 20px;
}

.contact-text h2 {
    font-family: 'Stylish';
    margin-bottom: 10px;
}

.contact-text p {
    font-family: 'Urbanist';
    margin-bottom: 0;
}
