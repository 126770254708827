/* src/components/BeforeAfterSlider.css */
.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: ew-resize;
  }
  
  .before-image,
  .after-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slider-handle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    background: rgba(255, 255, 255, 0.7);
    border: 2px solid #fff;
    cursor: ew-resize;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .handle {
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.7);
    border: 2px solid #000;
    border-radius: 50%;
  }
  