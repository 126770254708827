@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.products {
  position: relative;
  background-image: url('../../../assets/products/background.svg');
  background-size: cover;
  background-position: center;
  height: 700px; /* Fixed height for the background */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  overflow: hidden;
  padding: 20px; /* Adjust padding to fit border */
  margin-top: -20px;
}

.border-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 90%;
  z-index: 1; /* Ensure it's in front of the background */
  transform: translate(2.5%, 5%); /* Center horizontally and vertically */
}

.contentbrand {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the content takes full height of .products */
  position: relative;
  z-index: 2; /* Ensure content is in front of the border image */
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 100%;
  position: relative; /* Ensure positioning context for the brand section */
}

.left {
  text-align: left;
  width: auto;
  flex: 0 0 50%; /* Grow, shrink, basis */
  position: relative; /* Position context for the brand section */
}

.left h1 {
  font-family: "Meow Script";
  color: #DDB578;
  font-weight: 200;
}

.left p {
  font-family: "Sulphur Point";
  color: #ECE2D3;
}

.right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Position context for the brand section */
}

.red-paint-splash {
  position: absolute;
  width: 120%; /* Adjust the width as needed */
  height: 140%; /* Maintain aspect ratio */
  z-index: 0; /* Ensure it's behind the scroller */
  top: 50%; /* Adjust vertical position relative to the .right container */
  right: -10%; /* Align to the right side, adjust value as needed */
  transform: translateX(5%) translateY(-55%); /* Adjust vertical centering */
}

.image-scroller {
  width: 55%; /* Adjust the width relative to the .products container */
  height: 60%; /* Adjust the height relative to the .products container */
  max-width: 600px; /* Optional: Set a maximum width */
  max-height: 400px; /* Optional: Set a maximum height */
  position: relative;
  margin: 0 auto; /* Center horizontally within the .right container */
  z-index: 2; /* Ensure it's in front of the border image */
  transform: translateX(-25%) translateY(10%); /* Adjust vertical centering */
}

@media (max-width: 768px) {
  .image-scroller {
    width: 80%; /* Increase width to fit better on smaller screens */
    height: auto; /* Adjust height to maintain aspect ratio */
    margin-left: 0; /* Reset left margin if it's causing overflow */
    margin-right: 10px; /* Add right margin if needed */
  }
}

.slick-slide {
  display: flex;
  justify-content: center; /* Center slide content horizontally */
  align-items: center; /* Center slide content vertically */
  height: 100%; /* Ensure full height */
}

.slick-slide img {
  max-width: 100%; /* Ensure image fits within the slide width */
  max-height: 100%; /* Ensure image fits within the slide height */
  object-fit: contain; /* Adjust to 'cover' if you want to fill the slide area */
}

.slick-prev, .slick-next {
  z-index: 1;
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev {
  left: -30px;
}

.slick-next {
  right: -30px;
}

.slick-dots {
  bottom: 10px; /* Move dots up or down as needed */
}

.slick-dots li button:before {
  color: #CCB27F;
}

.slick-dots .slick-active button:before {
  color: #CCB27F;
}

.brand-section {
  color: #D9D9D9;
  font-family: "Sulphur Point";
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  padding-bottom: 50px;
}
.brand-section .brands-title {
  font-size: 24px;
  text-align: center;
}

.brand-logos-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.brand-logo {
  max-height: 10vh; /* Adjust size as needed */
  max-width: 12vw;
  height: auto;
}


.more-text {
  color: white; /* Adjust color as needed */
}

.shop-now-button {
  width: 200px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-top: 20px; /* Add some spacing above the button */
  cursor: pointer; /* Change cursor to pointer */
  transition: transform 0.3s ease; /* Add a smooth transition for hover effect */
}

.shop-now-button:hover {
  transform: scale(1.05); /* Slightly increase size on hover */
}

.sparkle-top-left, .sparkle-bottom-left {
  position: absolute;
  z-index: 3; /* Ensure sparkles are in front of other elements */
}

.sparkle-top-left {
  top: 0;
  right: 0;
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  transform: translate(50%, -20%); /* Adjust positioning */
}

.sparkle-bottom-left {
  bottom: 0;
  left: 0;
  width: 80px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  transform: translate(-100%, 20%); /* Adjust positioning */
}